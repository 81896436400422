import React from "react";
// Customizable Area Start

import {
  Container,
  Box,
  Button,
  Typography,
  Stepper, Step, StepLabel, StepContent, StylesProvider, Modal
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { imgVerification } from "./assets"; 
import LiveChat from "../../chat/src/LiveChat.web";

// Customizable Area End
import ReviewBlockController, {
  Props,
  configJSON
} from "./ReviewBlockController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ReviewBlock extends ReviewBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  body = () => (
    <Box className="modal-section">
      {!this.state.isQuestionUpdate && <img  src={imgVerification} alt="verificationicon" style={{ width: 300, height: 180, }} />}
      <Typography id="simple-modal-description" className="pending-verification-text">
      {this.state.isQuestionUpdate ? configJSON.QuestionUpdateTitleText : configJSON.QuestionTitleText}
      </Typography>
      <Typography  style={{textAlign:'center', fontFamily:'Urbanist',fontSize:'21px'}}>
      {this.state.isQuestionUpdate ? configJSON.QuestionUpdateDescriptionText : configJSON.QuestionDescriptionText}
      </Typography>
      <Button variant="outlined" data-test-id="closeModal" onClick={this.closeModal} className="okay-button">{this.state.isQuestionUpdate ? configJSON.goBackText : configJSON.okayText}</Button>
    </Box>
  );

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container style={{padding:'0px',maxWidth:'100%'}} className='topScroll'>
            <ReviewWrapper>
            <Box
              className="mainView" width={'100%'}
            >
              <Box
                className="leftView"
                style={{ backgroundColor: '#EEFFF8', }}
              >
                <img  {...this.imgCreditLiftProps} alt="Logo" style={{ width: 185, height: 53,marginBottom:20, }} />
                <img  {...this.txtLableStartedProps} alt="label" style={{ width: 400, height: 45,marginBottom:20, }} />
                <ReviewTypography>
                   <Typography style={{ fontFamily:'Urbanist',fontSize:'24px', fontWeight:400, }}>To keep connected with us please<br />enter your information.</Typography>
                </ReviewTypography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "30px 0px 0px 0px",
                    top: "100px"
                  }}
                >
                  <Stepper activeStep={3} orientation="vertical" className="muiStepper"
                  style={{ paddingBottom:"0px", paddingLeft: "0px" }}>
                    {this.state.stepperValue.map((label, index) => (
                      <Step key={label} color="success" style={{ height: '25px' }} >
                        <StepLabel color="#019F48">
                        <ReviewTypography> <Typography>{label}</Typography> </ReviewTypography>
                        </StepLabel>
                        <StepContent />
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
              <Box
                 className="rightView"
              >
                 <ReviewTypography>
                <Typography className="reviewText">Review</Typography>
                <Typography className="reviewDetail">Let’s Review your filled Details</Typography>
                </ReviewTypography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', boxShadow: 3, borderColor: '#333333'
                }} color={'red'}>
                  <Box sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Name</Typography>
                    <Typography className="textStyle">{`${this?.state?.userDetail?.user_name}`}</Typography>
                  </ReviewTypography>
                  </Box>
                  <Box  sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Email</Typography>
                    <Typography className="textStyle">{`${this?.state?.userDetail?.email}`}</Typography>
                  </ReviewTypography>
                  </Box>
                  <Box sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Phone Number</Typography>
                    <Typography className="textStyle">{`${this?.state?.userDetail?.full_phone_number}`}</Typography>
                  </ReviewTypography>
                  </Box>
                </Box>
                <ReviewTypography>
                  <Typography className="detailText">Address Details</Typography>
                </ReviewTypography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', boxShadow: 3, borderColor: '#333333'
                }} color={'red'}>
                  <Box sx={{ fontFamily: 'Urbanist',flex:1 }}>
                  <ReviewTypography>
                     <Typography className="labelTextStyle">Building/house No.</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.houseNumber}`}</Typography>
                    <Typography className="labelTextStyle">Postal Code</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.zip_code}`}</Typography>
                    </ReviewTypography>
                  </Box>
                  <Box sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Country</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.selectedCountry}`}</Typography>
                    <Typography className="labelTextStyle">State</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.state}`}</Typography>
                  </ReviewTypography>
                  </Box>
                  <Box sx={{ fontFamily: 'Urbanist',flex:1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Street</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.street}`}</Typography>
                    <Typography style={{ padding: 16, fontSize: 18, color: 'white', textAlign: 'left', }}>.</Typography>
                    <Typography style={{ padding: 16, fontSize: 18, color: 'white', textAlign: 'left', paddingTop: 5 }}>.</Typography>
                  </ReviewTypography>
                  </Box>
                </Box>
                <ReviewTypography>
                   <Typography className="detailText">Other Details</Typography>
                </ReviewTypography>
                <Box sx={{
                  display: "flex", fontFamily: 'Urbanist',
                  flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', boxShadow: 3, borderColor: '#333333'
                }} color={'red'}>
                  <Box sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">DOB</Typography>
                    <Typography className="textStyle">{this.getFormattedDob()}</Typography>
                  </ReviewTypography>
                  </Box>
                  <Box sx={{  flex: 1 }}>
                  <ReviewTypography>
                    <Typography className="labelTextStyle">Gender</Typography>
                    <Typography className="textStyle">{`${this?.state?.basicInfo?.gender ?? this?.state?.basicInfo?.gender}`}</Typography>
                  </ReviewTypography>

                  </Box>
                  <div style={{ color: 'white', flex: 1 }}>
                    <p>credlift</p>
                  </div>
                  <Box />
                </Box>
                <Box className="uploadHeadingText" style={{ marginLeft: "16px"}}>{configJSON.questionsHeading}</Box>
                <>
                  {this?.state?.questionsData?.length && this.state.questionsData.map((item: any, ind: string) => {
                    return (
                      <Box key={ind}>
                        <ReviewTypography>
                        <Typography className="questionLabel">{`${item.question}`}</Typography>
                        <Typography className="answer">{`${item.answer}`}</Typography>
                        </ReviewTypography>
                      </Box>
                    )
                  })}
                </>
                <Box className="uploadHeadingText">{configJSON.uploadDocumentsHeading}</Box>
                <Box className="uploadDocuments">
                 {
                 this.state.documents?.length>0&& this.state.documents.map((li:any,ind:number)=>{
                    return(
                      <Box sx={{
                        display: "flex", flexDirection: 'row',
                        fontFamily: 'Urbanist',
                        alignItems: 'center', justifyContent: 'space-between',whiteSpace:'nowrap',
                      }}
                        border={'1px solid rgba(1, 159, 72, 0.18)'}
                        borderRadius={8}
                        bgcolor={'#FFF'}
                        boxShadow={'0px 4px 11px 0px rgba(11, 109, 55, 0.07)'}
                        paddingLeft={3}
                        paddingRight={7}
                        key={ind}
                      >
                        <img  {...this.descriptionOutlineProps} alt="descriptionicon" style={{ width: 20, height: 20, }} />
                       <ReviewTypography>
                        <Typography className="labelTextStyle" style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '15rem' }}>{`${li?.file_name}`}</Typography>
                     
                       </ReviewTypography>  </Box>
                    )
                  })
                 }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontFamily: 'Urbanist'
                  }}
                >
                  <Box sx={{ padding: "20px 20px" }} border={0}>
                    <Button variant="outlined" data-test-id="back-button" className="backbtnStyle" onClick={() => this.props.navigation.goBack()}>Back</Button>
                  </Box>
                  <Box sx={{ padding: "20px 20px" }}>
                    <Button variant="contained"
                      color="secondary" style={{ background: '#019F48' }} data-test-id="next-button" className="btnStyle" onClick={this.getQuestionsData}>Next</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ReviewWrapper>
          </Container>
          <div>
          <ModalWrapper
            open={this.state.open}
            onClose={this.closeModal}
          >
            {this.body()}
          </ModalWrapper>
        </div>
        </ThemeProvider>
        <div style={{position: 'fixed', left: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', background: 'linear-gradient(99.09deg, #CEE1D6 2.64%, #23B058 100%)' }}>
        <LiveChat />
        </div>
      </StylesProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ModalWrapper = styled(Modal)({
  "& .modal-section": {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%,-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
    borderRadius: "20px",
    padding: "50px",
    gap: "35px",
    margin: "20px",
    boxSizing: "border-box",
    maxWidth: "700px",
    width: "100%"
  },
  '& .okay-button': {
    borderRadius: "12px",
    border: "1px solid #9E9E9E",
    display: "flex",
    width: "190px",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "30px"
  },
  '& .pending-verification-text': {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  }
})
const ReviewWrapper = styled(Box)({
  '& .mainView': {
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 900px)" : {
      flexDirection: "column",
      display: "flex",
    }

  },
  '& .uploadHeadingText': {
    color: "#333",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "35px",
    margin: "20px 0 20px 20px"
  },
  '& .leftView': {
    paddingTop: '56px',
    paddingRight: '30px',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: '30px',  
    backgroundColor: '#EEFFF8',
    paddingBottom: '0px',
    width:'35%',
    top: '0px',
    left: '0px',
    zIndex: 1,
"@media only screen and (max-width: 900px)" : {
  paddingLeft: '30px',
  backgroundColor: '#FFFF !important',
  alignSelf:'center',
  paddingTop: '20px',
  paddingRight: '30px',
  paddingBottom: '0px',
}

  },
  '& .infoView':{
    position:'relative',
    alignItems: "flex-start",
    marginTop:'35px',
    display: "flex",
    flexDirection: "column",
    "@media only screen and (max-width: 900px)" : {
      backgroundColor: '#FFFF !important',
    }
  },
  '& .rightView':{
      boxSizing: "border-box",
      paddingRight: '10px',
      paddingBottom: '0px',
      paddingLeft: '40px',
      fontFamily:'Urbanist',
      width:'70%',
      display: "flex",
      flexDirection: "column",
      paddingTop: '40px',
      "@media only screen and (max-width: 900px)" : {
        paddingLeft: '30px',
        backgroundColor: '#FFFF',
        paddingTop: '0px',
        paddingRight: '30px',
        paddingBottom: '0px',
      }
      
  },
  '& .loginBtnView':{
    justifyContent: 'center',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: 'center',
    fontFamily:'Urbanist',
    padding: "10px 0px",
    "@media only screen and (max-width: 900px)" :{
      fontSize: "12px",
    }
  },
  '& .reserved':{
  fontSize:'12px',
  fontFamily:'Urbanist',
  textAlign:'center',
},
'& .MuiStepIcon-root.MuiStepIcon-completed':{
  color:'#2aaf66',
  fontFamily:'Urbanist',
},
'& .MuiStepIcon-root.MuiStepIcon-active': {
  color:'#2aaf66',
  fontFamily:'Urbanist',
},
'& .backbtnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  fontWeight:'bold',
  textTransform: "capitalize"
},
'& .btnStyle':{
  fontSize:'22px',
  fontFamily:'Urbanist',
  textTransform:'none',
    color:'#FFFFF',
    fontWeight:'bold',
    "@media only screen and (max-width: 900px)" : {
      fontSize:'12px',
      fontFamily:'Urbanist',
  }
},
'& .uploadDocuments': {
  display: "flex", 
  paddingLeft: "20px",
  flexDirection: "row", 
  alignItems: 'center', 
  flexWrap: "wrap", 
  gap: "20px",
  borderColor: '#333333',
},
})
const ReviewTypography = styled(Typography)({
  '& .labelTextStyle':{ 
    padding: 16, 
    color: '#333333', 
    fontFamily:'Urbanist',
    textAlign: 'left',
    fontSize:'18px',
    fontWeight:'400',
    flex:1
},
'& .textStyle':{ 
  padding: 16, 
  fontFamily:'Urbanist',
  fontSize:'18px',
  color: '#333333', 
  fontWeight:'600',
  textAlign: 'left', 
  paddingTop: 5 
},
'& .reviewText':{ 
  paddingTop: 0, 
  fontSize: '38px', 
  color: '#333333', 
  paddingLeft: 20,
  fontFamily:'Urbanist',
  fontWeight: 700,
},
'& .reviewDetail':{ 
  paddingTop: 0, 
  fontSize: '18px', 
  color: '#333333', 
  paddingLeft: 20, 
  fontFamily:'Urbanist',
  paddingBottom: 20 
},
'& .detailText': {
  padding: 16, 
  fontSize: '24px', 
  color: '#333333', 
  textAlign: 'left', 
  fontWeight: 700,
  fontFamily:'Urbanist'
},
'& .questionLabel':{
  padding: 16, 
  fontSize: '18px', 
  color: '#333333', 
  textAlign: 'left',
  fontWeight: '400',
  fontFamily:'Urbanist',
},
'& .answer':{ 
  padding: 16, 
  fontSize: '18px', 
  color: '#333333', 
  textAlign: 'left',
  fontFamily:'Urbanist', 
  paddingTop: 0,
  fontWeight: '600', 
}
})
// Customizable Area End