import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Avatar,
  Divider
} from "@material-ui/core";
import { AvatarGroup } from '@material-ui/lab';
import { styled } from "@material-ui/core/styles";
import { bookImg, 
    chartImg, 
    shieldSecurityImg, 
    shieldSlashImg, 
    viewLineImg, 
    underLineImg, 
    bannerImage, 
    groupGroupImg, 
    imageLaptopImg, 
    groupRightImg,
    Young_woman_holdingImg,
    groupContentImg,
    imagePattern,
    aboutUsBottomLine,
    startComma,
    endComma,
    mobileImg,
    googlePlayApp,
    qrCodeImg,
    whiteLine
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import LiveChat from "../../chat/src/LiveChat.web";
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}

 // Customizable Area Start

//  getNextArrowImg = () => ;
//  getBackArrowImg = () => ;
 renderContent = (label: number, heading: string, description: string) => (
  <Box 
    className={`creditLiftWorkView ${this.getClassForLiftWork(label)}`} 
    boxShadow={label === this.state.activeContent ? '0px 8px 24px 0px rgba(1, 159, 72, 0.06)' : '0px 8px 0px 0px rgba(11, 109, 55, 0.10)'} 
    bgcolor={label === this.state.activeContent ? '#E6F5ED' : '#FFF'} 
    borderRadius={'16px'} 
    border={'1px solid #E7F0EB'}
    data-test-id="work-content"
    onClick={() => this.setState({ activeContent: label })}
  > 
    <Box style={{ color: label === this.state.activeContent ? "#019F48" : "#CBE4D6", fontSize: this.getFontSize(label)}} className="credit-lift-active-number">{label}</Box>
    <Box sx={{paddingLeft:10}}>
      <Box style={{ opacity: label === this.state.activeContent ? 1 : 0.6 }} className="credit-lift-work-number-heading">{heading}</Box>
      {this.state.activeContent === label && <Box>{description}</Box>}
    </Box>
  </Box>
)

renderSlickContent = () => {
  return this.state.landingPageData.testimonials.length > 0 ? this.state.landingPageData.testimonials.map((item) => <Box className="testimonial-section">
      <Box display="flex" justifyContent="center" style={{ gap: "16px" }} position="relative">
        <img height={50} width={50} src={startComma} />
        <Box className="testimonial-quote">{item.attributes.quote}</Box>
        <Box className="end-comma">
          <img height={50} width={50} src={endComma} />
        </Box>
      </Box>
      <Box className="testimonial-photo">
        <img height={80} width={80} className="testimonial-user-photo" src={item.attributes.photo} />
        <Box className="testimonial-username">{item.attributes.name}</Box>
      </Box>
    </Box>) : <div></div>;
}
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
        <Container maxWidth={false} disableGutters>
          <>
          <LandingMainWrapper>
            <Box className="linearBackground" >
              <NavigationMenu
                isLogo={true}
                navigation={this.props.navigation}
                id={this.props.id}
                screenName="Landing Page"
                fullWidthNavigation={false}
              />
             <Box>
            </Box>
            <img src={viewLineImg} className="viewLine" />
                <Box  className="landingMainBox main-container-wrap">
                <Box className="credit-lift-left-container" >
                  <Box color={"#019F48"} >
                   <Box className="boost-text">{configJSON.boostYourText}</Box>
                   <Box className="credit-score-text">{configJSON.creditScoreText} &#128640;</Box>
                  </Box>
                  <Box className="creditScoreDescription">{configJSON.creditLiftDescriptionText}<br/> {configJSON.increaseYourText} <span className="description-credit-score-text">{configJSON.creditScoreText}</span> {configJSON.nowText}</Box>
                  <Box className="button-wrapper">
                    <Button data-test-id="get-started-button" variant="contained" onClick={this.navigateToSignUp}  color="secondary" className="btnStyle">{configJSON.getStartedText}</Button>

                    <Button data-test-id="find-content" variant="outlined" className="findContentButton" onClick={this.navigateToLogin}>{configJSON.findCreditScore}</Button>
                </Box>
                <Box className="line-view" />
                <AvatarGroup>
                  <Avatar alt="Remy Sharp" src="" />
                  <Avatar alt="Travis Howard" src="" />
                  <Avatar alt="Agnes Walker" src="" />
                  <Avatar alt="Trevor Henderson" src="" />
                </AvatarGroup>
                <Box className="avatar-description">{configJSON.weAreText} 4.8 &#11088; {configJSON.ratedPlatform}<br/>{configJSON.customerBoostText}</Box>
              </Box>
              <Box className="credit-lift-logo-img">
              <img  src={bannerImage}  className="creditLiftLogo" style={{width:"100%",height:"auto"}}/>
              </Box>
      
            </Box>

            <Box sx={{textAlign:'center',fontSize:'40px', display: "flex", flexDirection: "column", alignItems: "center"}} color={'#33333'} p={5}>
                <Box className="heading-text">{configJSON.whyCreditLiftText}</Box>
                <img  src={underLineImg}  className="creditLiftLogo" style={{height:'3px',width:'80px'}}/>
                <Box className="creditLiftDescription">{this.state.landingPageData.why_credit_lift}</Box>
            </Box>
            <Box className="main-container-wrap creditScore-wrapper">
                <Box className="credtScore" boxShadow={'0px 8px 0px 0px rgba(11, 109, 55, 0.10)'} borderRadius={'20px'}
                  border={'1px solid rgba(1, 159, 72, 0.18)'} alignItems={'center'}>
                    <BookWrapper
                    data-test-id="close-button"
                    src={this.state.landingPageData.why_credit_lift_image[0]?.attributes.image.url}
                  />
                    <Box className="why-credit-lift-box-title-text">{this.state.landingPageData.why_credit_lift_image[0]?.attributes.title}</Box>
                    <Box className="why-credit-lift-box-description-text">{this.state.landingPageData.why_credit_lift_image[0]?.attributes.description}</Box></Box>
                    <Box className="credtScore" boxShadow={'0px 8px 0px 0px rgba(11, 109, 55, 0.10)'} borderRadius={'20px'}  margin={'10px'}
                    border={'1px solid rgba(1, 159, 72, 0.18)'} alignItems={'center'}>
                      <BookWrapper
                        data-test-id="close-button"
                        src={this.state.landingPageData.why_credit_lift_image[1]?.attributes.image.url}
                      />
                      <Box className="why-credit-lift-box-title-text">{this.state.landingPageData.why_credit_lift_image[1]?.attributes.title}</Box>
                      <Box className="why-credit-lift-box-description-text">{this.state.landingPageData.why_credit_lift_image[1]?.attributes.description}</Box>
                    </Box>
                  <Box className="credtScore" boxShadow={'0px 8px 0px 0px rgba(11, 109, 55, 0.10)'} borderRadius={'20px'} margin={'10px'}
                  border={'1px solid rgba(1, 159, 72, 0.18)'} alignItems={'center'}>
                    <BookWrapper
                        data-test-id="close-button"
                        src={this.state.landingPageData.why_credit_lift_image[2]?.attributes.image.url}
                      />
                    <Box className="why-credit-lift-box-title-text">{this.state.landingPageData.why_credit_lift_image[2]?.attributes.title} </Box>
                    <Box className="why-credit-lift-box-description-text">{this.state.landingPageData.why_credit_lift_image[2]?.attributes.description}</Box>
                  </Box>
                  <Box className="credtScore" boxShadow={'0px 8px 0px 0px rgba(11, 109, 55, 0.10)'} borderRadius={'20px'} margin={'10px'}
                  border={'1px solid rgba(1, 159, 72, 0.18)'} alignItems={'center'}>
                      <BookWrapper
                        data-test-id="close-button"
                        src={this.state.landingPageData.why_credit_lift_image[3]?.attributes.image.url}
                      />
                    <Box className="why-credit-lift-box-title-text">{this.state.landingPageData.why_credit_lift_image[3]?.attributes.title}</Box>
                    <Box className="why-credit-lift-box-description-text">{this.state.landingPageData.why_credit_lift_image[3]?.attributes.description}</Box></Box>
            </Box>
            <Box className="main-container-wrap">
            <Box className="people-trust-container" >
              <Box>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between' }} color={'white'}>
                  <Box className="people-trust-heading">People Trust Us</Box>
                  <img src={groupGroupImg} style={{width:48, height:40,marginLeft:10}} />
                </Box>
                <Box>
                  <Box className="people-trust-description">Company Value Quality You Can See.</Box>
                  <img src={imageLaptopImg} className="company-value-quality-image"/>
                </Box>
                
              </Box>
              <Box sx={{alignItems:'center',justifyContent:'center'}} color={'white'}>
                <Box sx={{display:'flex',}} borderBottom={'2px solid #21b16a'} textAlign={'center'}>
                  <Box className="people-trust-score-wrapper">
                    <Box className="people-trust-score-title">{this.state.landingPageData.partners}+</Box>
                    <Box className="people-trust-score-description">{configJSON.partnerText}</Box>
                  </Box>
                  <Box  borderLeft={'2px solid #21b16a'} className="people-trust-score-wrapper" paddingLeft={3} textAlign={'center'}>
                  <Box className="people-trust-score-title">{this.state.landingPageData.active_users}+</Box>
                    <Box className="people-trust-score-description">{configJSON.activeUserText}</Box>
                  </Box>
                </Box>
                <Box sx={{display:'flex',}}>
                  <Box borderLeft={'1px'}  className="people-trust-score-wrapper"  textAlign={'center'} paddingTop={3}>
                  <Box className="people-trust-score-title">{this.state.landingPageData.partners}+</Box>
                    <Box className="people-trust-score-description">{configJSON.partnerText}</Box>
                  </Box>
                  <Box borderLeft={'2px solid #21b16a'} className="people-trust-score-wrapper" textAlign={'center'} paddingTop={3} paddingLeft={3}>
                    <Box className="people-trust-score-title">{this.state.landingPageData.active_user_count}+</Box>
                    <Box className="people-trust-score-description">{configJSON.avgCreditScoreText}</Box>
                   </Box>
                </Box>
              </Box>
            </Box>
            </Box>
            <Box className="aboutUsView main-container-wrap">
                <Box>
                  <img src={groupRightImg} className="building-image" />
                </Box>
                <Box className="about-us-detail-section">
                  <Box className='heading-text'>About Us</Box>
                  <img  src={aboutUsBottomLine}   style={{ width:'80px', paddingTop: "5px" }}/>
                 <Box sx={{paddingTop:30}}>
                  <Box className="about-us-description-text">{this.state.about_us[0].description1}</Box>
                 <Box paddingTop={2} paddingBottom={2}>
                   <Box className="about-us-head-text">{this.state.about_us[0].subHeading}</Box>
                  </Box>
                  <Box className="about-us-description-text">{this.state.about_us[0].description2}</Box>
               
                 </Box>
                  </Box>

            </Box>
            <Box sx={{alignItems:'center',justifyContent:'center',padding:30}} textAlign={'center'} color={'#333333'}>
                  <Box className="heading-text">{configJSON.creditLiftWorkText}</Box>
                  <img  src={underLineImg}  className="creditLiftLogo" style={{height:'3px',width:'80px'}}/>
                  <Box paddingTop={3} paddingBottom={4} justifyContent={'center'} display={"flex"}> 
                  <Box className="credit-lift-work-description">{this.state.landingPageData.how_credit_lift_works}</Box>
                    </Box> </Box>
             <Box className="main-container-wrap credit-lift-work-wrapper">
              <Box>
              {this.renderContent(1, this.state.landingPageData.how_credit_lift_works_disc[0]?.heading, this.state.landingPageData.how_credit_lift_works_disc[0]?.description,)}
              {this.renderContent(2, this.state.landingPageData.how_credit_lift_works_disc[1]?.heading, this.state.landingPageData.how_credit_lift_works_disc[1]?.description,)}
              {this.renderContent(3, this.state.landingPageData.how_credit_lift_works_disc[2]?.heading, this.state.landingPageData.how_credit_lift_works_disc[2]?.description,)}
              {this.renderContent(4, this.state.landingPageData.how_credit_lift_works_disc[3]?.heading, this.state.landingPageData.how_credit_lift_works_disc[3]?.description,)}
                </Box>
                <Box sx={{alignItems:'center', justifyContent:'center'}}>
                   <img  src={groupContentImg}  className="creditLiftLogo" style={{height:'auto',width:'100%'}}/>
                   <Box>
                  </Box>
                </Box>
             </Box>
             <Box className="downloadCreditImg main-container-wrap">
              <Box className="download-image-content">
                <Box className="heading-text">{configJSON.downloadCreditAppText}</Box>
                <img src={whiteLine} alt="white-line" />
                <Box className="subheading-text">{configJSON.downloadCreditAppDescription1}</Box>
                <Box className="subheading2-text" >{configJSON.downloadCreditAppDescription21} <span>{configJSON.downloadCreditAppDescription22}</span></Box>
                <Box className="scan-content">
                  <img className="qr-image" src={qrCodeImg} alt="qr-code" />
                  <Box className="divider-container">
                    <Divider className="white-divider" orientation="vertical" />
                    <Box className="or-text">{configJSON.orText}</Box>
                    <Divider className="white-divider" orientation="vertical" />
                  </Box>
                  <Box className="googleAppWrapper">
                  <img src={googlePlayApp} alt="google-play-app" className="googleAppIcon"/>
                  </Box>
                </Box>
              </Box>
              <img className='mobile-image' src={mobileImg} alt="mobile-image" />
              </Box>
              <Box sx={{alignItems:'center',justifyContent:'center',padding:30}} textAlign={'center'} color={'#333333'}>
                  <Box className="heading-text">Testimonials</Box >
                  <img  src={underLineImg}  className="creditLiftLogo" style={{height:'3px',width:'80px'}}/>
               </Box>
               <Box position="relative" className="main-container-wrap" maxWidth="100%" overflow="hidden">
                  <Slider ref={this.sliderRef} dots={true} adaptiveHeight={true}>{this.renderSlickContent()}</Slider>
                  <Box className="buttons-wrap">
                    <img className={this.getBackClassName()} src={this.getBackImage()} style={{ paddingRight: "8px"}} data-test-id="next-slide-button" onClick={this.handleBackSlide} />
                    <img className={this.getNextClassName()} src={this.getNextImage()} data-test-id="prev-slide-button" onClick={this.handleNextSlide} />
                  </Box>
               </Box>
              <Box className="getStartrd main-container-wrap"  bgcolor={'#FD8D02'} borderRadius={'24px'} m={15}>
                <Box  boxShadow={'0px -23px 34px 0px rgba(0, 0, 0'}>
                  <img src={Young_woman_holdingImg}
                    className="get-started-image"
                  />
                </Box>
                <Box className="get-started-description-text" color={'white'}>
                  <Box className="get-start-text">{configJSON.getStartedText}</Box>
                  <Box className="get-start-description-text">Welcome! I'm here to help you get started on your journey.<br/>Whether you're starting a new journey, embarking on a new venture, </Box>
                </Box>
                <Box className="signUpNow" color={'#fff'}>
                    <Button variant="outlined" data-test-id="signup-button" onClick={this.navigateToSignUp} className="sign-up-now-button">{configJSON.startNow}</Button>
                </Box>
              </Box>
              <FooterWrapper>
                <Footer 
                    id={this.props.id} 
                    navigation={this.props.navigation} 
                    socialMediaLinkData={this.state.socialMediaLinks} 
                    contactUsData={this.state.contactUsData}
                />
              </FooterWrapper>
              </Box>
            </LandingMainWrapper>
          </>
          <Box style={{position: 'fixed', right: '30px',bottom: '20px', zIndex: 444, borderRadius: '12px', backgroundColor: "#019F48"}}> 
           <LiveChat />
          </Box>
        </Container>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const LandingMainWrapper = styled(Box)({
    '& .viewLine': {
        position: "absolute",
        height: '480px',
        top: 0,
        width: "100%",
        "@media only screen and (max-width: 767px)" : {
          display: "none"
        }
    },
    '& .about-us-detail-section': {
      paddingLeft: "50px",
      fontFamily: 'Urbanist', 
      border: '0px solid white !important',
      "@media only screen and (max-width: 800px)" : {
        paddingLeft: 0,
      }
    },
    '& .slick-slide': {
      height: "auto"
    },
    '& .slick-list': {
      padding: "15px 0 60px"
    },
    '& .slick-dots': {
      bottom: 0
    },
    '& .slick-dots li button::before': {
      fontSize: "10px"
    },
    '& .slick-dots li.slick-active button::before': {
      opacity: 1,
      color: '#019F48'
    },
    '& .slick-dots li button:hover::before': {
      opacity: 0.35,
    },
    '& .get-started-description-text': {
      alignItems:'center',
      justifyContent:'center',
      "@media only screen and (max-width: 767px)" : {
        paddingLeft: "20px"
      }
    },
  '& .buttons-wrap': {
    position: "absolute",
    bottom: 0,
    right: 0,
    '& .pointer': {
      cursor: 'pointer'
    }
  },
  '& .testimonial-section': {
    boxSizing: "border-box",
    borderRadius: "24px",
    border: "1px solid rgba(11, 109, 55, 0.18)",
    opacity: 0.9,
    background: "#FFF",
    boxShadow: "0px 8px 0px 0px rgba(11, 109, 55, 0.10)",
    padding: "40px 80px",
    textAlign: "center",
    position: "relative",
    "@media only screen and (max-width: 768px)" : {
      padding: "20px"
    },
    '& .testimonial-photo': {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    '& .testimonial-user-photo': {
      borderRadius: "50%",
      marginTop: "18px"
    },
    '& .testimonial-quote': {
      color: "#333",
      textAlign: "center",
      fontFamily: "Urbanist",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "44px",
      padding: "18px 0",
      "@media only screen and (max-width: 1000px)" : {
        fontSize: "18px",
        lineHeight: "30px"
      }
    },
    '& .end-comma': {
      display: "flex",
      alignItems: "end",
    },
    '& .testimonial-username': {
      color: "#333",
      textAlign: "center",
      fontFamily: "Urbanist",
      fontSize: "20px",
      fontWeight: 600,
      marginTop: '18px',
      "@media only screen and (max-width: 1000px)" : {
        fontSize: "14px"
      }
    },
  },
  '& .linearBackground': {
    paddingTop: '20px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #E1FFF1 0%, rgba(251, 253, 255, 0.47) 20.09%)',
    alignItems:'center',
    justifyContent:'center',
    color: "#333",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "@media only screen and (max-width: 767px)" : {
      background: "#FFF"
    }
  },
'& .credit-lift-work-number-heading': {
  fontSize: "20px", 
  fontWeight: 700, 
  lineHeight: "30px"
},
'& .credit-lift-work-number-description': {
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "26px"
},
'& .people-trust-score-wrapper': {
    width: "200px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media only screen and (max-width: 900px)" : {
      width: "160px"
    },
    "@media only screen and (max-width: 710px)" : {
      width: "110px"
    }
},
'& .people-trust-container': {
  overflowX: 'scroll',
  marginTop:'20px',
  position: "relative",
  flexDirection:'row',
  display:'flex',
  background: `url(${imagePattern})`,
  padding: "30px",
  justifyContent:'space-between', 
  boxSizing: "border-box",
  borderRadius: "20px", 
  backgroundColor: '#019F48',
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  width: '100%',
  scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  "@media only screen and (max-width: 760px)" : {
    padding: "10px"
  },
},
'& .main-container-wrap': {
  margin: "0 150px 100px",
  "@media only screen and (max-width: 1300px)" : {
    margin: "0 70px 60px"
  },
  "@media only screen and (max-width: 767px)" : {
    margin: "0 24px 24px"
  }
},
'& .credit-lift-work-number-inactive-heading': {
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "30px",
  opacity: 0.6,
  "@media only screen and (max-width: 1000px)" : {
    fontSize: "18px"
  }
},
'& .boost-text': {
  color: "#333",
  fontSize: "56px",
  fontStyle: "italic",
  fontWeight: 900,
  fontFamily: "Urbanist",
  "@media only screen and (max-width: 900px)" : {
    fontSize: "30px"
  }
},
'& .building-image': {
  width:'520px',
  height:'450px',
  display: "block",
  "@media only screen and (max-width: 1200px)" : {
    width:'400px',
    height:'375px',
  },
  "@media only screen and (max-width: 900px)" : {
    width:'350px',
    height:'330px',
  },
  "@media only screen and (max-width: 800px)" : {
    display: "none"
  },
},
'& .credit-lift-work-wrapper': {
  gap: "100px",
  flexDirection:'row',
  display:'flex',
  alignItems:'center',
  "&>div": {
    width: "50%",
    "@media only screen and (max-width: 1000px)" : {
      width: "100%"
    }
  },
  "@media only screen and (max-width: 1000px)" : {
    flexDirection: 'column-reverse',
    gap: "0px"
  }
},
"& .about-us-description-text": {
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px"
},
'& .why-credit-lift-box-title-text': {
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px"
},
"& .credit-lift-work-description": {
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  maxWidth: '800px'
},
'& .company-value-quality-image': {
  width: "220px",
  height: "172px",
  position: "absolute",
  bottom: 0,
  "@media only screen and (max-width: 540px)" : {
    width: "175px"
  },
  "@media only screen and (max-width: 470px)" : {
    width: "150px",
    height: "140px",
  }
},
"& .people-trust-heading": {
  color: "#FFF",
  textShadow: "0px 4px 14px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "@media only screen and (max-width: 1000px)" : {
    fontSize: "20px"
  }
},
"& .people-trust-description": {
  color: "#FFF",
  textShadow: "0px 4px 14px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "30px",
  "@media only screen and (max-width: 1000px)" : {
    fontSize: "14px",
    lineHeight: "18px"
  }
},
'& .credit-lift-left-container': {
  width: "50%",
  "@media only screen and (max-width: 767px)" : {
    width: "100%"
  }
},
'& .credit-lift-logo-img': {
  width: "50%",
  display: "block",
  "@media only screen and (max-width: 767px)" : {
    display: "none"
  }
},
"& .credit-lift-active-number": {
  color: "#019F48",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "72px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal"
},
"& .credit-lift-inactive-number": {
  color: "#CBE4D6",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "48px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal"
},
"& .people-trust-score-title": {
  color: "#FFF",
  textEdge: "cap",
  textShadow: "0px 4px 14px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "@media only screen and (max-width: 1000px)" : {
    fontSize: "25px"
  }
},
"& .people-trust-score-description": {
  color: "#FFF",
  textAlign: "center",
  textShadow: "0px 4px 14px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "26px",
  "@media only screen and (max-width: 1000px)" : {
    fontSize: "15px"
  },
  "@media only screen and (max-width: 710px)" : {
    fontSize: "10px"
  }
},
'& .why-credit-lift-box-description-text': {
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "26px"
},
'& .credit-score-text': {
  color: "#019F48",
  fontFamily: "Urbanist",
  fontSize: "56px",
  fontStyle: "italic",
  fontWeight: 900,
  "@media only screen and (max-width: 900px)" : {
    fontSize: "30px"
  }
},
"& .about-us-head-text": {
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "44px",
  "@media only screen and (max-width: 900px)" : {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500
  }
},
'& .heading-text': {
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "@media only screen and (max-width: 900px)" : {
    fontSize: "25px"
  }
},
'& .avatar-description': {
  marginTop: "15px",
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  "@media only screen and (max-width: 900px)" : {
    fontSize: "14px",
    lineHeight: "20px"
  }
},
'& .creditScoreDescription': {
  paddingTop:'15px',
  paddingBottom:'15px',
  color: '#333',
  fontSize: '20px',
  lineHeight: '30px',
  "@media only screen and (max-width: 900px)" : {
    fontSize: "14px",
    lineHeight: "20px"
  }
},
'& .get-start-text': {
  color: "#FFF",
  textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "30px",
  "@media only screen and (max-width: 1200px)" : {
    lineHeight: "25px",
    fontSize: "25px",
  },
  "@media only screen and (max-width: 700px)" : {
    lineHeight: "18px",
    fontSize: "16px",
  }
},
'& .get-started-image': {
  height:'250px',
  width:'277px',
  marginTop:'-55px',
  display: "block",
  "@media only screen and (max-width: 900px)" : {
    height:'150px',
    width:'160px',
    marginTop:'18px',
  },
  "@media only screen and (max-width: 767px)" : {
    display: "none"
  }
},
'& .get-start-description-text': {
  marginTop: "26px",
  color: "#FFF",
  textShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "27px",
  "@media only screen and (max-width: 1200px)" : {
    marginTop: "10px",
    lineHeight: "25px",
    fontSize: "14px",
  },
  "@media only screen and (max-width: 700px)" : {
    marginTop: "6px",
    lineHeight: "15px",
    fontSize: "12px",
  }
},
'& .description-credit-score-text': {
  fontSize: "20px", 
  fontWeight: 700, 
  lineHeight: "30px",
  "@media only screen and (max-width: 900px)" : {
    fontSize: "14px",
    lineHeight: "20px"
  }
},
'& .landingMainBox': {
  flexDirection:'row',
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  marginTop: "60px !important"
},
'& .sign-up-now-button': {
  display: "flex",
  width: "185px",
  height: "56px",
  textTransform: "none",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  borderRadius: "12px",
  border: "1px solid #9E9E9E",
  background: "#FFF",
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  "@media only screen and (max-width: 900px)" : {
    height:'50px',
    width:'125px',
    fontSize:'14px',
  },
  "@media only screen and (max-width: 500px)" : {
    width:'100px',
    fontSize:'10px',
  }
},
'& .line-view': {
  maxWidth: "100%",
  width: "100%",
  height: "1px",
  flexShrink: 0,
  background: "linear-gradient(90deg, #019F48 1.42%, rgba(255, 255, 255, 0.00) 76.33%)",
  margin: "40px 0",
  "@media only screen and (max-width: 900px)" : {
    margin: "30px 0",
  }
},
'& .btnStyle': {
  display: "inline-flex",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  color: "#FFF",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  textTransform: "none",
  borderRadius: "12px",
  background: "#019F48",
  width: "210px",
  "@media only screen and (max-width: 900px)" : {
    width: "140px",
    height: "40px",
    fontSize: "15px"
  }
},
'& .button-wrapper': {
  display: "flex",
  gap: "20px",
  "@media only screen and (max-width: 1150px)" : {
    flexDirection: "column"
  },
  "@media only screen and (max-width: 900px)" : {
    gap: "14px"
  }
},
'& .creditLiftDescription': {
  marginTop: "10px",
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  maxWidth: '800px',
  "@media only screen and (max-width: 900px)" : {
    fontSize: "15px",
    lineHeight: "24px"
  }
},
'& .findContentButton': {
  display: "flex",
  width: "248px",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  borderRadius: "12px",
  border: "1px solid #9E9E9E",
  background: "#FFF",
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  textTransform: "none",
  "@media only screen and (max-width: 900px)" : {
    width: "205px",
    height: "40px",
    fontSize: "15px"
  }
},
'& .getStartrd':{
  flexDirection:'row',
  display:'flex',
  marginTop: "45px !important",
  paddingTop: "20px",
  paddingBottom: "20px",
  height:'139px',
  alignItems:'center',
  justifyContent:'space-between',
  "@media only screen and (max-width: 900px)" : {
    paddingTop: "10px",
    paddingBottom: "10px"
  }
},
'& .creditLiftWorkView': {
  flexDirection:'row',
  display:'flex',
  marginTop:"22px",
  alignItems:'center',
  gap: "24px",
  padding:'22px 24px',
  cursor: 'pointer',
  "@media only screen and (max-width: 1000px)" : {
    padding: "8px 18px"
  },
},
'& .active-credit-lift': {
  padding: '30px 24px',
  "@media only screen and (max-width: 1000px)" : {
    padding: "10px 18px"
  },
},
'& .downloadCreditImg':{
  marginTop: '225px !important',
  padding: '40px 40px 40px 50px',
  borderRadius: '24px',
  background: `url(${imagePattern}), lightgray 50% / cover no-repeat`,
  position: 'relative',
  backgroundColor: '#019F48',
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  "@media only screen and (max-width: 1000px)" : {
    padding: "20px",
    marginTop: '0 !important',
  },
  '& .download-image-content': {
    width: "calc(100% - 440px)",
    "@media only screen and (max-width: 1000px)" : {
      width: '100%'
    }
  },
  '& .mobile-image': {
    position: 'absolute',
    bottom: 0,
    right: 42,
    "@media only screen and (max-width: 1000px)" : {
      display: 'none'
    }
  },
  '& .heading-text': {
    color: "#FFF",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "47px",
    "@media only screen and (max-width: 1000px)" : {
      fontSize: "28px",
      lineHeight: "35px"
    },
  },
  '& .subheading-text': {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "30px",
    paddingTop: '20px',
    "@media only screen and (max-width: 1000px)" : {
      fontSize: "14px",
      lineHeight: "22px"
    },
  },
  '& .subheading2-text': {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "30px",
    "@media only screen and (max-width: 1000px)" : {
      fontSize: "14px",
      lineHeight: "22px"
    },
    '&>span' : {
      fontWeight: 700
    }
  },
  '& .qr-image': {
    height: 80,
    width: 80
  },
  '& .scan-content': {
    display: 'flex',
    gap: '25px',
    alignItems: "center",
    marginTop: '10px'
  },
  '& .divider-container': {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center'
  },
  '& .googleAppWrapper':{
    overflowX: "hidden"
  },
  '& .googleAppIcon':{
    "@media only screen and (max-width: 767px)" : {
      width: "-moz-available",
      height: "70px"
    }
  },
  '& .white-divider': {
    height: '30px',
    width: '2px',
    backgroundColor: '#FFF'
  },
  '& .or-text': {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "30px"
  }
},
'& .signUpNow':{alignItems:'center',justifyContent:'center',padding:20},
'& .aboutUsView': {flexDirection:'row',display:'flex'},
'& .creditScore-wrapper': {
  display: "flex",
  flexDirection: "row",
  gap: "30px",
  "@media only screen and (max-width: 1000px)" : {
    flexWrap: "wrap"
  },
},
'& .credtScore':{
  justifyContent:'center',
  textAlign:'center', 
  padding: "40px 14px 30px", 
  width: "25%",
  boxShadow: '0px 8px 0px 0px rgba(11, 109, 55, 0.10)',
  borderRadius: '20px',
  margin: '10px',
  border: '1px solid rgba(1, 159, 72, 0.18)',
  alignItems: 'center',
  "@media only screen and (max-width: 1000px)" : {
    width: "calc(50% - 35px)",
    boxSizing: "border-box"
  },
  "@media only screen and (max-width: 900px)" : {
    padding: "20px 10px 10px",
  },
  "@media only screen and (max-width: 767px)" : {
    width: "100%"
  }
}
})
  const ChartButtonWrapper = styled("img")({
    cursor: "pointer",
    padding:10,
    "@media only screen and (max-width: 767px)": {
      width: "80px"
    },
  });
  const BookWrapper = styled("img")({
    cursor: "pointer",
    padding:10,
    width:"85px"
  });

  const FooterWrapper = styled(Box)({
    display: "block",
    "@media only screen and (max-width: 767px)": {
      display: "none"
    }
  })
// Customizable Area End