import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const configJSON = require("./config");


// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      // Customizable Area Start
      main: "#019f48",
      // Customizable Area End
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start       
    <ThemeProvider theme={theme}>
      <Container style={{padding:'0px',maxWidth:'100%'}} ref={this.scrollRef}>
        <LoginMainView>
          <Box
          className="mainView"
            width={'100%'}
          >
            <Box
              className="leftView"
            >
              <TypograyView>
              <Typography variant="h4" component="h2" className="labelStyle">
                  {this.labelTitle}
                </Typography>
                <Typography variant="subtitle1" component="div" className="labelLoginStyle">
                  {this.state.labelHeader}
                </Typography>
                <Box style={{flexDirection:'row',display:'flex'}}>
                <span className="handEmoji">&#128075;</span> <Typography className="welcomeText">Welcome Back!</Typography></Box>
                <img {...this.imgVerticalLineProps} alt="GreenLine" className="loginVerticaliLine"
                />
                <Typography className="personalInfo">To keep connected with us please enter <br />your personal information.</Typography>
              </TypograyView>
              <Box
                className="infoView"
                width={'100%'}
              >
                <img  {...this.imgGraphProps} alt="graphimage" style={{ width: '309px', height: '309px', marginLeft: '-34px', }} className="graphLoginImage" />
                <img  {...this.imgWatcProps} alt="watchimage" className="watchImage" />
                <img  {...this.imgUploadDocProps} alt="uploadimage" style={{ width: '384px', height: '309px', marginLeft: '-34px', }} className="imageLoginUpload" />
              </Box>
            </Box>
            <Box
              className="rightView"
            >
              <img  {...this.imgCreditLiftProps} alt="creditliftlogo" className="creditLiftLogo" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 0px",
                }}
              >
                <TypograyView>
                  <Typography variant="h4" component="h2" className="labelBlock">
                    {'Login'}
                  </Typography>
                  <Typography variant="subtitle1" component="div"  className="subLabelBlock">
                    {'Login your account'} {/*UI Engine::From Sketch*/}
                  </Typography>  
                </TypograyView>
                <Box sx={{ paddingTop: "20px" }} width={'100%'}>
                  <Box sx={{ padding: "20px 0px" }} width={'100%'}>
                    <InputLabel htmlFor="outlined-adornment-amount" style={{fontFamily: 'Urbanist', fontSize: '18px', fontWeight: '400', color: '#333'}}>{'Email'}</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="txtInputEmail"
                      value={this.state.email}
                      className={this.state.invalidEmail || this.state.emailError || this.state.emailRegisterError? "input-error" : ""}
                      error={this.state.emailError || this.state.invalidEmail}
                      onChange={(e) => this.setEmail(e.target.value)}
                      placeholder={"Email"}
                      InputProps={{
                        style: {
                          marginTop: 8,
                          backgroundColor: '#F4FAF7',
                          fontFamily: 'Urbanist',
                          fontSize: '18px',
                          fontWeight: '400',
                          
                          borderRadius: '12px',
                        }
                      }}
                    />
                    {
              (this.state.emailError || this.state.invalidEmail || this.state.emailRegisterError) && <TypograyView><Typography className="error">{this.getEmailErrorText()}</Typography></TypograyView>
             }
                  </Box>
                  <Box sx={{ padding: "20px 0px" }}>
                    <InputLabel htmlFor="outlined-adornment-amount" style={{fontFamily: 'Urbanist', fontSize: '18px', fontWeight: '400', color: '#333'}}>{'Password'}</InputLabel>
                    <TextField
                      variant="outlined"
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={'Password'}
                      fullWidth={true}
                      value={this.state.password}
                      className={this.state.pwdError || this.state.invalidPassword ? "input-error" : ""}
                      error={this.state.pwdError || this.state.invalidPassword}
                      onChange={(e) => this.setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-test-id="visibilityPwd"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>,
                        style: {
                          marginTop: 8,
                          backgroundColor: '#F4FAF7',
                          fontFamily: 'Urbanist',
                          fontSize: '18px',
                          fontWeight: '400',                          
                        borderRadius: '12px',
                        }
                      }} />
                      {this.state.errormsgShow ? (<TypograyView><Typography className="error">{this.state.errormsgShow}</Typography> </TypograyView>
                          ) : (
                       (this.state.pwdError || this.state.invalidPassword) && <TypograyView>
                        <Typography className="error">{this.getPasswordErrorText()}</Typography></TypograyView> )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent:"space-between"
                    }}
                  >
                    <Box
                      className="rememberMeView"
                    >
                      <Checkbox
                        data-test-id={"btnRememberMe"}
                        onClick={() =>
                          this.setRememberMe(!this.state.checkedRememberMe)
                        }
                        className={'checkBox accept-Rem'}
                        style={{padding: '9px 9px 9px 0px'}}
                        checked={this.state.checkedRememberMe}
                      />{" "}
                      Remember Me
                    </Box>
                    <Box
                      data-test-id={"btnForgotPassword"}
                      className="forgotPasswordText"
                      onClick={this.handleForgotPassword}
                      style={{ cursor: "pointer"}}
                    >
                      Forgot Password?
                    </Box>
                  </Box>

                  {this.state.inactivateAccount && <TypograyView>
                    <Typography className="error">{configJSON.inActivateAccountText}</Typography>
                  </TypograyView>}
                  <Box
                    className="loginBtnView"
                  >
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      fullWidth
                      onClick={() => this.login()}
                      style={{ backgroundColor: '#019F48', textTransform: 'none',borderRadius:'12px' }}
                    >
                      Login {/*UI Engine::From Sketch*/}
                    </Button>
                  </Box>
                  <Box
                    className="account"
                  >
                    {'Don’t have an account? '}<Box
                      sx={{
                        color: '#019F48'
                      }}
                      style={{ cursor: "pointer", paddingLeft: '5px' ,textDecoration:'underline',fontWeight:'bold'}}
                      data-test-id="sign-up-button"
                      onClick={this.handleSignUp}
                    >Sign Up</Box>
                  </Box>
                  <Box sx={{display:'flex', justifyContent: 'center', alignItems:'end', marginTop : '225px'}}>
                  <Box sx={{ fontSize: '14px', marginTop:'40px', marginBottom: '20px', fontWeight:'400px',}} pt={6} color={'#333333'}>
                    <footer className="reserved">© Credit Lift. All Rights Reserved</footer>
                  </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          </Box>
        </LoginMainView>
      </Container>

    </ThemeProvider>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const TypograyView = styled(Typography)({
  '& .welcomeText': {
    fontSize: '40px',
    fontFamily: 'Urbanist',
    color: '#333',
    fontWeight: 'bold',
    paddingLeft:'20px',
    "@media only screen and (max-width: 900px)" : {
      display:'none !important'
    }
  },
  '& .personalInfo':{ 
    fontFamily:'Urbanist',
    fontSize:'24px',
    paddingTop: 20,
    paddingBottom:30,
    "@media only screen and (max-width: 900px)" : {
      display:'none !important'
    }
   },
   '& .labelStyle':{
      display:'none !important',
      "@media only screen and (max-width: 900px)" : {
        display: 'block !important',
        alignSelf:'center',
        textAlign:'center',
        fontFamily:'Urbanist',
        fontSize:'20px'
      }
    },
    '& .labelLoginStyle':{
      display:'none !important',
      "@media only screen and (max-width: 900px)" : {
        display: 'block !important',
        alignSelf:'center',
        textAlign:'center',
        fontSize:'12px',
        color:'#292D32',
        fontFamily:'Urbanist'
      }
    },
    '& .labelBlock': {
      display: 'block !important',
      fontFamily:'Urbanist',
      fontSize: '38px',
      color: '#333',
      fontWeight: '700',
      paddingBottom: '10px',
      "@media only screen and (max-width: 900px)" : {
        display: 'none !important'
      }

  },
  '& .subLabelBlock': {
    display: 'block !important',
    fontFamily:'Urbanist',
    fontSize: '26px',
    color: '#333',
    "@media only screen and (max-width: 900px)" : {
      display: 'none !important'
    }

}
});
const LoginMainView = styled(Box)({
  width:'100%',
  padding:'0px',
  '& .mainView': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width:'100%',
    "@media only screen and (max-width: 900px)" : {
      display: "flex",
      flexDirection: "column",
    }

  },
  '& .leftView': {
    width:'650px',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "100px",
    paddingTop: '56px',
    paddingRight: '72px',
    paddingBottom: '0px',
    paddingLeft: '72px',
backgroundColor: '#EEFFF8',
"@media only screen and (max-width: 900px)" : {
  paddingTop: '20px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    backgroundColor: '#FFFF',
    alignSelf:'center',
    width:'auto !important',
}

  },
  '& .infoView':{
    position:'relative',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop:'35px',
    // top: "100px",
  },
  '& .rightView':{
    width:'60%',
      display: "flex",
      flexDirection: "column",
      paddingTop: '56px',
      paddingRight: '112px',
      paddingBottom: '0px',
      paddingLeft: '72px',
      fontFamily:'Urbanist',
      "@media only screen and (max-width: 900px)" : {
        paddingTop: '0px',
          paddingRight: '30px',
          paddingBottom: '0px',
          paddingLeft: '30px',
          backgroundColor: '#FFFF',
          width:'85%'
      }
      
  },
  '& .rememberMeView':{
    fontSize: "18px",
    color: "#333333",
    fontWeight: "400",
    marginTop: "5px",
    fontFamily:'Urbanist',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .forgotPasswordText':{
    fontSize: "18px",
    color: "#333",
    lineHeight:'35px',
    fontWeight: "bold",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    paddingLeft: "20px",
    border:'0px !important',
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .loginBtnView':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius:'12px',
    padding: "20px 0px",
    fontFamily:'Urbanist',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
    }
  },
  '& .account':{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 0px",
    fontSize: '18px',
    border: '0px solid white !important',
    // textAlign:'center',
    fontFamily:'Urbanist',
    justifyContent:'center',
    "@media only screen and (max-width: 900px)" : {
      fontSize: "12px",
      // textAlign:'center !important',
    }
  },
  '& .reserved':{
  fontSize:'12px',
  textAlign:'center',
  fontFamily:'Urbanist',
},
'& .loginVerticaliLine': {
  position: 'absolute',
  top: '-20px', left: '0px', transform: 'rotate(0deg)',
  width: '700', height: '1070px'
},
'& .error':{
  color:'#FF0000',
  fontSize:'18px',
  fontFamily:'Urbanist',
  paddingTop:'10px'
},
'& .handEmoji':{
  fontSize:'40px',
  fontFamily:'Urbanist',
  color:'#333',
  fontWeight:'bold',
  paddingLeft:'0px',
"@media only screen and (max-width: 900px)" : {
  display: 'none !important'
},
},
'& .PrivateSwitchBase-root-114':{
  paddingLeft:'0px important'
 },
'& .Mui-checked': {
  color: '#019f48'
},
'& .MuiButton-contained': {
  fontSize:'22px',
  fontFamily:'Urbanist',
  color: '#fff',
},
});
// Customizable Area End
