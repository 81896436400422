import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  StylesProvider,
  InputLabel,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  RadioGroup,
  FormControlLabel,
  MenuProps,
  Popper,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import "./infoPageBlock.web.css";
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { KeyboardArrowDown } from "@material-ui/icons";
// Customizable Area End

import InfoPageBlockController, {
  Props, configJSON,
} from "./InfoPageBlockController";
import { calendar } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#019F48",
      contrastText: "#fff",
    },
  },
});

export default class InfoPageBlock extends InfoPageBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container style={{ padding: '0px', maxWidth: '100%' }} className="topScroll">
            <InfoPagenWrapper>
              <Box
                className="mainView" width={'100%'}
              >
                <Box
                  className="leftView"
                  bgcolor={'#EEFFF8'}
                >
                  <img  {...this.imgCreditLiftProps} alt ="CreditLift" className="creditLiftLogo" />

                  <img  {...this.txtLableStartedProps} alt="Lets Get Started" style={{ width: 400, height: 45,marginBottom:20, }}className="txtLableStarted" />
                  <TypographyWrapper>
                    <Typography className="letsGet">Let's get you started</Typography>
                    <Typography className="keepInfo">to keep your information</Typography>
                    <Typography className="txtInfo">To keep connected with us please<br />enter your information.</Typography>
                  </TypographyWrapper>
                  <Box className="start"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "30px 0px 0px 0px",
                      top: "100px",
                      fontFamily: 'Urbanist',
                      marginTop:'20px'
                    }}
                  >{
                    (JSON.parse(localStorage.getItem('userData')??'[]').role_id==='User') ? <Stepper activeStep={0}  orientation="vertical"
                      className="muiStepper"
                      style={{ paddingBottom: 0, }}>
                      {this.state.stepperValue.map((label, index) => (
                        <StepperSection key={label} color="success" style={{ height: '25px' }} >
                          <StepLabel >
                            <Typography>{label}</Typography>
                          </StepLabel>
                          <StepContent />
                        </StepperSection>
                      ))}
                    </Stepper>
                    : <Stepper activeStep={0}  orientation="vertical"
                      className="muiStepper"
                      style={{ paddingBottom: 0, }}>
                      {this.state.stepper2.map((label, index) => (
                        <StepperSection key={label} color="success" style={{ height: '25px' }} >
                          <StepLabel >
                            <Typography>{label}</Typography>
                          </StepLabel>
                          <StepContent />
                        </StepperSection>
                      ))}
                    </Stepper>
                  }
                  </Box>
                </Box>
                <Box
                  className="rightView"
                >
                  <TypographyWrapper>
                    <Typography className="heading">Basic Details</Typography>
                    <Typography className="subHeading">Enter your basic details</Typography>
                    <Typography className="addressDetail">Address Details</Typography>
                  </TypographyWrapper>
                  <Box className="inputeView" >
                    <Box className="inputeSubView">
                      <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle" style={{ fontFamily: 'Urbanist',  fontSize: 18, fontWeight: 500, color: "black", }}>{'Building/House No.'}</InputLabel>
                      <TextField 
                        fullWidth
                        className={this.state.errors.houseNumber ? "input-error" : ""}
                        error={this.state.errors.houseNumber}
                        variant="outlined"
                        data-test-id="txtInputEmail"
                        // fullWidth={true}
                        value={this.state.houseNumber}
                        onChange={(e) => this.setHouse(e.target.value)}
                        placeholder={"Building/House No."}
                        InputProps={{
                          style: {
                            marginTop: 8,
                            backgroundColor: '#F4FAF7',
                            border:'1px solid #CEE1D6',
                            borderRadius: 12,
                            
                            outline: 'none',


                          },
                          classes: {
                            focused: 'Mui-focused'
                          }
                        }}
                      />
                      {this.state.errors.houseNumber && <Box className="error">{configJSON.houseNumberErrorText}</Box>}
                    </Box>
                    <Box className="inputeSubView" >
                      <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle" style={{ fontFamily: 'Urbanist', fontSize: 18, fontWeight: 500, color: "black" }}>{'Country'}</InputLabel>
                      <Autocomplete 
                        forcePopupIcon={false}
                        popupIcon = {<KeyboardArrowDown/>}
                        disabled
                        value={'USA'}
                        title={this.state.selectedCountry}
                        options={this.state.countryList.map(item => item[1])}
                        //@ts-ignore
                        onChange={this.handleCountryChange}
                        renderOption={(option, { selected }) => (
                          <MenuItemWrap disableRipple selected={selected}>
                            <Box title={option as string} onClick={() => this.setCountry(option as string)} className={selected ? 'active-item' : 'not-active-item'}>{option}</Box>
                          </MenuItemWrap>
                        )}
                        style={{
                          marginTop: 8, backgroundColor: '#F4FAF7', fontFamily: 'Urbanist'
                        }}
                        PopperComponent={(props) => <PopperWrapper {...props} />}
                        {...menuProps}
                        renderInput={(params) => (
                          <CustomTextField                            
                            {...params}
                            fullWidth
                            placeholder="Country"
                            variant="outlined"
                            className={this.state.errors.country ? "input-error" : ""}
                            error={this.state.errors.country}
                          />
                        )}
                      />
                      {this.state.errors.country && <Box className="error">{configJSON.countryErrorText}</Box>}
                    </Box>
                  </Box>
                  <Box className="inputeView"
                  >
                    <Box className="inputeSubView">
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'Street'}</InputLabel>
                      </InputLabelWrapper>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-test-id="txtInputEmail"
                        className={this.state.errors.street ? "input-error" : ""}
                        error={this.state.errors.street}
                        value={this.state.street}
                        onChange={(e) => this.setStreet(e.target.value)}
                        placeholder={"Street"}
                        InputProps={{
                          style: {
                            marginTop: 8,
                            backgroundColor: '#F4FAF7',
                            borderRadius: 12,
                            border: "1px solid #CEE1D6",
                            outline: 'none'
                          },
                          classes: {
                            focused: 'Mui-focused'
                          }

                        }}
                      />
                      {this.state.errors.street && <Box className="error">{configJSON.streetErrorText}</Box>}
                    </Box>
                    <Box className="inputeSubView">
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'Postal Code'}</InputLabel>
                      </InputLabelWrapper>
                      <TextField
                        onKeyPress={this.handleKeyPress}
                        fullWidth
                        variant="outlined"
                        data-test-id="txtInputEmail"
                        value={this.state.postalCode}
                        onChange={(e) => this.setPostalCode(e.target.value)}
                        placeholder={"Postal Code"}
                        className={this.state.errors.postalCode ? "input-error" : ""}
                        error={this.state.errors.postalCode}
                        InputProps={{
                          style: {
                            marginTop: 8,
                            backgroundColor: '#F4FAF7',
                            borderRadius: 12,
                            border: "1px solid #CEE1D6",
                            outline: 'none'
                          },
                          classes: {
                            focused: 'Mui-focused'
                          }

                        }}
                      />
                      {this.state.errors.postalCode && <Box className="error">{configJSON.postalCodeFormatErrorText(this.state.postalCode)}</Box>}
                    </Box>
                  </Box>
                  <Box className="inputeView"
                  >
                    <Box className="stateInputeSubView">
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'State'}</InputLabel>
                      </InputLabelWrapper>
                      <Autocomplete
                        popupIcon = {<KeyboardArrowDown/>}
                        value={this.state.stateData}
                        options={this.state.stateList}
                        title={this.state.stateData}
                        //@ts-ignore
                        onChange={this.handleStateChange}
                        renderOption={(option, { selected }) => (
                          <MenuItemWrap title={option as string} disableRipple selected={selected}>
                            <Box className={selected ? 'active-item' : 'not-active-item'}>{option}</Box>
                          </MenuItemWrap>
                        )}
                        style={{
                          marginTop: 8, backgroundColor: '#F4FAF7', fontFamily: 'Urbanist'
                        }}
                        PopperComponent={(props) => <PopperWrapper  {...props} />}
                        {...menuProps}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder="State"
                            variant="outlined"
                            className={this.state.errors.state ? "input-error" : ""}
                            error={this.state.errors.state}
                            disabled={!this.state.country}
                            
                          />
                        )}
                      />
                      {this.state.errors.state && <Box className="error">{configJSON.stateErrorText}</Box>}
                    </Box>
                    <Box sx={{ padding: "10px 20px", maxWidth: '100%' }}></Box>
                  </Box>
                  <Box sx={{ padding: "10px 20px", maxWidth: '100%' }}>
                    <TypographyWrapper>
                      <Typography className="otherName">Other Details</Typography>

                    </TypographyWrapper>
                  </Box>
                  <Box
                    className="inputeView"
                  >
                    <Box className="inputeSubView" sx={{ position: "relative" }}>
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'Birthdate'}</InputLabel>
                      </InputLabelWrapper>
                      <Box className="dob-field" style={{ color: `rgba(0, 0, 0, ${this.state.dob ? "1" : "0.38"})` }}>
                        {this.getFormattedDate() || "DOB"} 
                      </Box> 
                      
                       <TextField 

                        id="date"
                        label=""
                        fullWidth
                        variant="outlined"
                        type="date"
                        ref={this.myref}
                        className={this.state.errors.dob ? "input-error" : ""}
                        error={this.state.errors.dob}
                        defaultValue={this.state.dob}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                onClick={()=>{console.log(this.myref.current);
                                console.log(this.myref.current.querySelector(".MuiOutlinedInput-input"))
                              let a= this.myref.current.querySelector(".MuiOutlinedInput-input")
                            a.showPicker()}}

                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src ={calendar} alt="calenderlogo" />
                              </IconButton>
                            </InputAdornment>
                          ,
                          inputProps: { min: this.getCalendarMinDate().toISOString().split('T')[0], max: this.getCalendarMaxDate().toISOString().split('T')[0] },
                        }}
                        onChange={(e) => {
                          this.setDateOfBirth(e.target.value)
                          this.setState({ errors: { ...this.state.errors, dob: false } })
                        }}
                        style={{ marginTop: 20, backgroundColor: '#F4FAF7'}}
                      /> 
                      {this.state.errors.dob && <Box className="error">{configJSON.dobErrorText}</Box>}
                    </Box>
                    <Box className="inputeSubView">
                      <InputLabelWrapper>
                        <InputLabel htmlFor="outlined-adornment-amount" className="inputeLabelStyle">{'Gender'}</InputLabel>
                      </InputLabelWrapper>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        value={this.state.genderType}
                        style={{ alignItems: 'center', justifyContent: 'space-between' }}
                        onChange={(e) => this.setGenderType(e.target.value)}


                      >
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '0px',
                          border: this.state.errors.genderType ? "1px solid red" : `1px solid ${this.state.genderType === 'Male' ? '#019F48' : '#CEE1D6'}`,
                          borderRadius: 12,
                          padding: '5px 10px',
                          bgcolor: '#F4FAF7',
                          color: this.state.genderType === 'Male' ? '#019F48' : '#CEE1D6'
                        }}
                        className={this.state.errors.genderType ? "input-error" : ""}
                        mt={2.5}

                          borderColor={'#CEE1D6'}>
                          <FormControlLabel
                            value="Male"
                            control={<Checkbox icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />} color="primary" checked={this.state.genderType === 'Male'}
                              onChange={() => this.setGenderType('Male')} />}
                            label="Male"
                            labelPlacement="start"
                            style={{ width: "9vw", display: "flex", alignItems: "center", justifyContent: "space-between", }}

                          />
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '0px',
                          border: this.state.errors.genderType ? "1px solid red" :`1px solid ${this.state.genderType === 'Female' ? '#019F48' : '#CEE1D6'}`,
                          borderRadius: 12,
                          padding: '5px 10px',
                          bgcolor: '#F4FAF7',
                          color: this.state.genderType === 'Female' ? '#019F48' : '#CEE1D6'

                        }}
                        className={this.state.errors.genderType ? "input-error" : ""}
                          mt={2.5}
                          borderColor={'#CEE1D6'} >

                          <FormControlLabel
                            value="Female"
                            control={<Checkbox icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />} color="primary" checked={this.state.genderType === 'Female'}
                              onChange={() => this.setGenderType('Female')} />}
                            label="Female"
                            labelPlacement="start"
                            style={{ width: "9vw", display: "flex", alignItems: "center", justifyContent: "space-between", }}


                          />
                        </Box>
                      </RadioGroup>
                      {this.state.errors.genderType && (
                      <Typography variant="body2" color="error" style={{ fontSize:'18px',fontWeight:'500px',paddingTop:'10px', fontFamily:'Urbanist'}}>
                        Please select one gender
                      </Typography>
                      )}

                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: 'center',
                      justifyContent: 'end'
                    }}
                    border={'1px'}
                  >
                    <Box sx={{ padding: "20px 20px" }}>
                      <Button variant="contained" color="secondary" className="btnStyle" onClick={() => {
                        this.handleValidation()
                      }}>Next</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </InfoPagenWrapper>
          </Container>
        </ThemeProvider>
      </StylesProvider>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input.Mui-disabled': {
    color: 'black'
  },
}));

const PopperWrapper = styled(Popper)({
  '& .MuiAutocomplete-paper': {
    border: '1px solid rgba(1, 159, 72, 0.18)',
    marginTop: '4px',
  },
  '& .MuiAutocomplete-option, .MuiAutocomplete-listbox': {
    padding: 0,
    
  },
  '& .MuiListItem-root': {
    width: '100%',
  }
});

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  MenuListProps: {
    style: {
      padding: 0
    },
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: "156px",
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
      border: "1px solid rgba(1, 159, 72, 0.18)",
      marginTop: "4px"
    }
  }
};

const StepperSection = styled(Step)({
  "&>svg>circle": {
    color: 'green'
  },
});

const MenuItemWrap = styled(MenuItem)({
  padding: 0,
  "@media screen and (max-width: 600px)": {
    minHeight: "auto",
  },
  '& .active-item': {
    color: "#019F48",
    fontWeight: 600
  },
  '& .not-active-item': {
    color: "#333",
    fontWeight: 500
  },
  '&>div': {
    padding: "6px 16px",
    background: "#FFF !important",
    lineHeight: "normal",
    fontSize: "16px",
    fontFamily: "Urbanist",
    width: "100%",
    '&:hover': {
      background: 'rgba(1, 159, 72, 0.15) !important'
    }
  }
});

const InfoPagenWrapper = styled(Box)({
  '& .mainView': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "@media only screen and (max-width: 900px)": {
      display: "flex",
      flexDirection: "column",
    }

  },
  '& .MuiSelect-select': {
    '&:focus': {
      backgroundColor: '#F4FAF7'
    }
  },
  '& .dob-field': {
    position: "absolute",
    top: "57px",
    zIndex: 1111,
    background: "#f4faf7",
    width: "calc(100% - 100px)",
    display: "flex",
    alignItems: "center",
    height: "40px",
    left: "30px",

    paddingLeft: "5px",
    "@media only screen and (max-width: 900px)": {
      left: "10px",
    }

  },
  '& .error': {
    fontSize: "18px",
    color: "#F00",
    fontWeight: 500,
    fontFamily: "Urbanist",
    paddingTop: "10px"
  },
  '& .leftView': {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "100px",
    paddingTop: '56px',
    paddingRight: '30px',
    paddingBottom: '0px',
    paddingLeft: '30px',
    backgroundColor: '#EEFFF8',
    width: '40%',
    "@media only screen and (max-width: 900px)": {
      paddingTop: '20px',
      paddingRight: '30px',
      paddingBottom: '0px',
      paddingLeft: '30px',
      backgroundColor: '#FFFF !important',
      alignSelf: 'center',
      width: 'auto'
    }

  },
  '& .infoView': {
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: '35px',
    "@media only screen and (max-width: 900px)": {
      backgroundColor: '#FFFF !important',

    }
  },
  '& .rightView': {
    display: "flex",
    flexDirection: "column",
    paddingTop: '56px',
    paddingRight: '112px',
    paddingBottom: '0px',
    paddingLeft: '72px',
    fontFamily: 'Urbanist',
    width: '60%',
    "@media only screen and (max-width: 900px)": {
      paddingTop: '0px',
      paddingRight: '30px',
      paddingBottom: '0px',
      paddingLeft: '30px',
      backgroundColor: '#FFFF',
      width: '85%'
    }

  },
  '& .loginBtnView': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
    alignSelf: 'center',
    padding: "10px 0px",
    fontFamily: 'Urbanist',
    "@media only screen and (max-width: 900px)": {
      fontSize: "12px",
    }
  },
  '& .reserved': {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Urbanist'
  },
  '& .inputeView': {
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 900px)": {
      display: "flex",
      flexDirection: "column",
      fontWeight: 500,
      color: 'black'
    }
  },
  '& .inputeSubView': {
    padding: "10px 20px",
    width: '100%',
    fontFamily: 'Urbanist',
    
    "@media only screen and (max-width: 900px)": {
      padding: "10px 0px !important",
      fontFamily: 'Urbanist !important',
      fontWeight: 500,
      color: 'black'
    },
  },
  '& .stateInputeSubView': {
    padding: "10px 20px", minWidth: '46%',
    "@media only screen and (max-width: 900px)": {
      padding: "10px 0px !important",
    },


  },
  '& .demo1': {
    borderRadius: "12px",
    border: "1px solid #1CEE1D6"

  },
  '& .MuiRadio-colorSecondary.Mui-checked': {
    color: '#019f48'
  },
  '& .backbtnStyle': {
    textTransform: 'capitalize',
    fontSize: '22px',
    fontFamily: 'Urbanist',
    fontWeight: 'bold',
  },
  '& .btnStyle': {
    fontSize: '22px',
    fontFamily: 'Urbanist',
    textTransform: 'none',
    color: '#FFFFF',
    fontWeight: 'bold',
    "@media only screen and (max-width: 900px)": {
      fontSize: '12px',
      fontFamily: 'Urbanist',
    }
  }
})
const TypographyWrapper = styled(Typography)({
  '& .heading': {
    "@media only screen and (max-width: 900px)": {
      display: 'none !important'
    }
  },
  '& .subHeading': {
    "@media only screen and (max-width: 900px)": {
      display: 'none !important',
      fontWeight: "bold"
    }
  },
  '& .addressDetail': {
    "@media only screen and (max-width: 900px)": {
      display: 'none !important',
      fontWeight: "bold"
    }
  },
  '& .otherDetail': {
    paddingLeft: '20px',
    "@media only screen and (max-width: 900px)": {
      paddingLeft: '0px',
      fontWeight: "bold"
    }
  },
  '& .txtInfo': {
    fontFamily: 'Urbanist',
    fontSize: '24px',
    fontWeight: "400px",
    "@media only screen and (max-width: 900px)": {
      display: 'none !important'
    }
  },
  '& .letsGet': {
    display: 'none !important',
    "@media only screen and (max-width: 900px)": {
      fontSize: '20px',
      color: '#292D32',
      display: 'block !important',
      fontFamily: 'Urbanist',
      fontWeight: "bold",
      textAlign: 'center'
    }
  },
  '& .keepInfo': {
    display: 'none !important',
    "@media only screen and (max-width: 900px)": {
      fontSize: '12px',
      color: '#292D32',
      display: 'block !important',
      fontFamily: 'Urbanist',
      fontWeight: 500,
      alignSelf: 'center',
      textAlign: 'center'
    }
  },
  '& .otherName': {
    fontFamily: 'Urbanist',
    fontSize: '24px',
    color: '#333',
    fontWeight: '700'
  }


});
const InputLabelWrapper = styled(InputLabel)({
  '& .inputeLabelStyle': {
    fontSize: '18px',
    color: 'black',
    fontFamily: 'Urbanist',
    fontWeight: 500

  },
  '& .MuiButton-root': {
    textTransform: 'none',
    fontSize: '22px',
    color: '#959A9B',
    fontFamily: 'Urbanist',
  },
})
// Customizable Area End